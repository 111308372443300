@font-face {
  font-family: 'Leitura Display';
  src: local('Leitura Display'), local('LeituraDisplay'),
    url(../Fonts/LeituraDisplayRoman.otf) format('truetype');
}

#root {
  height: 100%;
}

.input {
  border: 0px;
  width: 100%;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #9f9fa3;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 15px 0px;
  border-bottom: #212f52 1px solid;
  padding-bottom: 15px;
  border-radius: 0;
  &:focus {
    outline: none !important;
    border-bottom: 1px solid black;
    box-shadow: none;
  }
  &.input:disabled {
    background-color: #fff !important;
  }
}

.edit-profile-input {
  border: 0px;
  width: 100%;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #9f9fa3;
  padding-bottom: 10px;
  margin: 10px 0px;
  border-bottom: 1px solid #212f52;
  outline: none;
  border-radius: 0px;
  &:focus {
    outline: none !important;
    border-bottom: 1px solid #212f52;
    box-shadow: none !important;
  }
}

.slick-dots li button:before {
  border-radius: 50%;
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  width: 10px;
  height: 10px;
  content: '•';
  text-align: center;
  opacity: 0.75;
  color: #ffffff;
  border: 1px solid #212f52;
  -webkit-font-smoothing: antialiased;
  position: inherit;
}

.slick-dots li.slick-active button:before {
  background: #212f52;
  border: 0px;
}

.slick-dots {
  bottom: -75px;
}

.form-check-input[type='checkbox'] {
  padding: 8px;
  border-radius: 4px;
  :hover {
    cursor: pointer;
  }
}

.form-check-input:checked {
  background-color: #212f52;
  border-color: #212f52;
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
  display: -webkit-box;
}

.date-picker-icon {
  float: left;
  margin-right: 6px;
  // margin-top: -30px;
  position: relative;
  z-index: 2;
}

.date-picker-icon2 {
  display: flex;
  @media (max-width: 425px) {
    display: flex;
  }
}

.popover-header {
  background-color: #212f52;
  color: #fff;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.popover-body {
  background-color: #fff;
  color: #212f52;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.DefaultProgressBar__vertical {
  width: 24px;
  height: 100%;
}
.DefaultProgressBar_progressBar {
  background-color: #212f52;
  position: absolute;
  height: 4px;
  cursor: pointer;
}
.DefaultProgressBar_progressBar__vertical {
  height: 100%;
  width: 24px;
}
.DefaultProgressBar_background__vertical {
  height: 100%;
  top: 0px;
  width: 15px;
}
.DefaultProgressBar_background__horizontal {
  // height: 15px;
  top: 0px;
}
.DefaultHandle_handle {
  width: 24px;
  height: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: #d8d8d8;
  background-color: #fcfcfc;
  border-radius: 50%;
  outline: none;
  z-index: 2;
  margin-inline-start: -12px;
  box-shadow: 0 2px 2px #dbdbdb;
}
.DefaultHandle_handle:focus {
  box-shadow: #abc4e8 0 0 1px 1px;
}
.DefaultHandle_handle:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #dadfe8;
}
.DefaultHandle_handle:before {
  content: '';
  display: block;
  position: absolute;
  background-color: #dadfe8;
}
.DefaultHandle_handle__horizontal {
  margin-left: -12px;
  top: -8px;
}
.DefaultHandle_handle__vertical {
  margin-top: -12px;
  left: -10px;
}
.DefaultHandle_handle__vertical:before {
  top: 10px;
}
.DefaultHandle_handle__vertical:after {
  top: 13px;
  left: 8px;
  height: 1px;
  width: 10px;
}
.DefaultHandle_handle__disabled {
  border-color: #dbdbdb;
}
.DefaultBackground {
  background-color: lightgrey;
  height: 15px;
  width: 100%;
  // border: 1px solid #d8d8d8;
  // position: relative;
}
.DefaultBackground_background__horizontal {
  height: 4px;
  cursor: pointer;
  background: #c7c7c7;
  // top: -2px;
  // left: -2px;
  // bottom: 4px;
  width: 100%;
}
.DefaultBackground_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%;
}
.rheostat {
  position: relative;
  overflow: visible;
  // margin: 30px 22px;
}
@media (min-width: 1128px) {
  .autoAdjustVerticalPosition {
    top: 12px;
  }
}
.rheostat__vertical {
  height: 100%;
}
.handleContainer {
  height: 15px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%;
  position: absolute;
}
.rheostat_background {
  background-color: #fcfcfc;
  border: 1px solid #d8d8d8;
  position: relative;
}
.rheostat_background__horizontal {
  height: 15px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%;
}
.rheostat_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%;
}

.dropdown-button-price {
  padding: 28px 32px;
  width: 375px;
}

.dropdown.show {
  .dropdown-toggle {
    background: #14254e;
    border-radius: 50%;
  }
}

.dropdown-menu.show {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.nav-link {
  padding: 0.5em;
}

.signup-dialog {
  max-width: 600px !important;
}

.dropdown-header-price {
  color: #212f52;
  font-family: Arial;
  font-weight: 800;
  font-size: 16px;
  line-height: normal;
}

#react-select-3-input {
  color: #212f52;
}

.dropdown-price-button {
  font-size: 15px;
}

.guest-info-label {
  color: #212f52;
  font-family: Arial;
  line-height: normal;
  font-weight: 800;
  font-size: 16px;
}

.guest-info-formcontrol {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  color: #212f52;
  &:hover {
    outline: none;
  }
  &:focus {
    outline: none !important;
    border: 1px solid #d8d8d8;
    box-shadow: none;
  }
}

.form-select {
  color: #212f52;
}

.payment-info-label {
  color: #212f52;
  font-family: Arial;
  line-height: normal;
  font-weight: 800;
  font-size: 16px;
}

.payment-info-formcontrol {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  color: #212f52 !important;
  &:hover {
    outline: none;
  }
  &:focus {
    outline: none !important;
    border: 1px solid #d8d8d8;
    box-shadow: none;
  }
  &::placeholder {
    color: #212f52 !important;
  }
}

.success-container {
  left: 50%;
  top: 50%;
  width: 600px;
  transform: translate(-50%, -50%);
  position: fixed;
}
.modalbox.success {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background: #fff;
  padding: 25px 25px 15px;
  text-align: center;
}
.modalbox.success.animate .icon {
  -webkit-animation: fall-in 0.75s;
  -moz-animation: fall-in 0.75s;
  -o-animation: fall-in 0.75s;
  animation: fall-in 0.75s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.modalbox.success h1 {
  font-family: 'Montserrat', sans-serif;
}
.modalbox.success p {
  font-family: 'Open Sans', sans-serif;
}
.modalbox.success .icon {
  position: relative;
  margin: 0 auto;
  margin-top: -75px;
  background: #4caf50;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
.modalbox.success .icon span {
  // position: absolute;
  font-size: 4em;
  color: #fff;
  text-align: center;
  padding-top: 20px;
}
.center {
  float: none;
  margin-left: auto;
  margin-right: auto;
  /* stupid browser compat. smh */
}
.center .change {
  // clear: both;
  display: block;
  font-size: 10px;
  color: #ccc;
  margin-top: 10px;
}

.add-promo-code {
  border: 1px solid #212f52;
  border-radius: 33px;
  &:hover {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}

.serchbar-col {
  @media (max-width: 425px) {
    height: auto;
    display: flex;
  }
}

.forget-password-input {
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0px;
  margin: 30px 0px;
  color: #212529;
  &:focus {
    outline: none !important;
    // border-bottom: 1px solid black;
    box-shadow: none;
  }
}

.newpassword-input {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid black;
  margin-bottom: 20px;
}

#txtPassword {
  -webkit-text-security: disc !important;
}

.message-input:focus {
  box-shadow: none;
}

// payment loader
.wrapper-payment {
  width: 200px;
  height: 60px;
  // position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #212f52;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.wrapper-payment span {
  position: absolute;
  top: 75px;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 12px;
  color: #212f52;
  left: 15%;
}
